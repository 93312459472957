import React from 'react';

import { Order } from '../../types';
import styled from '@emotion/styled';
import Heading3 from '../common/Heading3';
import Text from '../common/Text';
import { useTranslation } from 'react-i18next';

type Props = {
  order?: Order;
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled(Heading3)`
  margin-bottom: 8px;
  padding-top: 20px;
`;

const TextWithLineBreaks = styled(Text)`
  white-space: pre-wrap;
`;

function LiteOverviewContent(props: Props) {
  const { order } = props;
  const { t } = useTranslation();

  return (
    <Container>
      <Text>{t('overviewLite_customerName')}</Text>
      <Text small secondary>
        {order?.customerName}
      </Text>
      <StyledHeading>{t('overviewLite_contentHeading')}</StyledHeading>
      <TextWithLineBreaks secondary>{t('overviewLite_contentMessage')}</TextWithLineBreaks>
    </Container>
  );
}

export default React.memo(LiteOverviewContent);
